/*
 * Skin: Blue
 * ----------
 */
.content-wrapper {
  background-color: #f5f9fc;
}
.skin-blue .main-header .navbar {
  background-color: #f5f9fc;
  border-bottom: #e5e9f2 solid 1px;
}
.skin-blue .main-header .navbar .nav > li > a {
  color: #ffffff;
}
.skin-blue .main-header .navbar .nav > li > a:hover,
.skin-blue .main-header .navbar .nav > li > a:active,
.skin-blue .main-header .navbar .nav > li > a:focus,
.skin-blue .main-header .navbar .nav .open > a,
.skin-blue .main-header .navbar .nav .open > a:hover,
.skin-blue .main-header .navbar .nav .open > a:focus,
.skin-blue .main-header .navbar .nav > .active > a {
  background: rgba(0, 0, 0, 0.1);
  color: #f6f6f6;
}
.skin-blue .main-header .navbar .sidebar-toggle {
  color: #ffffff;
}
.skin-blue .main-header .navbar .sidebar-toggle:hover {
  color: #f6f6f6;
  background: rgba(0, 0, 0, 0.1);
}
.skin-blue .main-header .navbar .sidebar-toggle {
  color: #fff;
}
.skin-blue .main-header .navbar .sidebar-toggle:hover {
  background-color: #367fa9;
}
@media (max-width: 767px) {
  .skin-blue .main-header .navbar .dropdown-menu li.divider {
    background-color: rgba(255, 255, 255, 0.1);
  }
  .skin-blue .main-header .navbar .dropdown-menu li a {
    color: #fff;
  }
  .skin-blue .main-header .navbar .dropdown-menu li a:hover {
    background: #367fa9;
  }
}
.skin-blue .main-header .logo {
  background-color: #ff1617;
  color: #ffffff;
  border-bottom: 0 solid transparent;
}
.skin-blue .main-header .logo:hover {
  background-color: #ff1617;
}
.skin-blue .main-header li.user-header {
  background-color: #3c8dbc;
}
.skin-blue .content-header {
  background: transparent;
}
.skin-blue .wrapper,
.skin-blue .main-sidebar,
.skin-blue .left-side {
  background-color: #222222;
}
.skin-blue .user-panel > .info,
.skin-blue .user-panel > .info > a {
  color: #fff;
}
.skin-blue .sidebar-menu > li.header {
  color: #4b646f;
  background: #222222;
}
.skin-blue .sidebar-menu > li > a {
  border-left: 3px solid transparent;
}
.skin-blue .sidebar-menu > li:hover > a,
.skin-blue .sidebar-menu > li.active > a,
.skin-blue .sidebar-menu > li.menu-open > a {
  color: #ffffff;
  background: #1b1b1b;
}
.skin-blue .sidebar-menu > li.active > a {
  border-left-color: #fff;
}
.skin-blue .sidebar-menu > li > .treeview-menu {
  margin: 0 1px;
  background: #2c3b41;
}
.skin-blue .sidebar a {
  color: rgb(179, 179, 179);
}
.skin-blue .sidebar a:hover {
  text-decoration: none;
}
.skin-blue .sidebar-menu .treeview-menu > li > a {
  color: #8aa4af;
}
.skin-blue .sidebar-menu .treeview-menu > li.active > a,
.skin-blue .sidebar-menu .treeview-menu > li > a:hover {
  color: #ffffff;
}
.skin-blue .sidebar-form {
  border-radius: 3px;
  border: 1px solid #374850;
  margin: 10px 10px;
}
.skin-blue .sidebar-form input[type="text"],
.skin-blue .sidebar-form .btn {
  box-shadow: none;
  background-color: #374850;
  border: 1px solid transparent;
  height: 35px;
}
.skin-blue .sidebar-form input[type="text"] {
  color: #666;
  border-top-left-radius: 2px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 2px;
}
.skin-blue .sidebar-form input[type="text"]:focus,
.skin-blue .sidebar-form input[type="text"]:focus + .input-group-btn .btn {
  background-color: #fff;
  color: #666;
}
.skin-blue .sidebar-form input[type="text"]:focus + .input-group-btn .btn {
  border-left-color: #fff;
}
.skin-blue .sidebar-form .btn {
  color: #999;
  border-top-left-radius: 0;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 0;
}
.skin-blue.layout-top-nav .main-header > .logo {
  background-color: #3c8dbc;
  color: #ffffff;
  border-bottom: 0 solid transparent;
}
.skin-blue.layout-top-nav .main-header > .logo:hover {
  background-color: #3b8ab8;
}

.progress_box {
  background: #fff;
  border-radius: 4px;
  border: #e5e9f2 solid 1px;
  padding: 30px 15px 5px 15px;
  margin-top: 15px;
}
.progress_box p {
  font-size: 14px;
}
.progress_box h2 {
  margin: 0;
  padding: 0;
  font-size: 30px;
  font-weight: normal;
}
.progress_box label {
  color: #0bafee;
  font-size: 14px;
  font-weight: bold;
}
.progress_box .progress {
  height: 2px;
  background: #e5e9f2;
}
.progress_box .progress-bar {
  height: 2px;
  background: #0bafee;
}
.section_box {
  background: #fff;
  border-radius: 4px;
  border: #e5e9f2 solid 1px;
  margin-top: 30px;
}
.section_box_in {
  background: #fff;
  border-radius: 4px;
  border-top: #e5e9f2 solid 1px;
  padding: 20px;
}
.section_box h2 {
  padding: 10px 20px;
  margin: 0;
  font-size: 18px;
  font-weight: normal;
}

.Notification_tab .nav-tabs-custom > .nav-tabs > li {
  border-bottom: 2px solid transparent;
  border-top: 0;
  border-left: 0;
  border-right: 0;
}
.Notification_tab .nav-tabs-custom > .nav-tabs > li.active {
  border-bottom-color: #0bafee;
}
.Notification_tab .nav-tabs-custom > .nav-tabs > li.active > a {
  border-left: 0;
  border-right: 0;
}
.Notification_tab .label {
  color: rgb(134, 133, 133);
  font-weight: normal;
}
.Notification_tab a {
  color: #666;
}
.Notification_tab .products-list .product-img img {
  border-radius: 50%;
  width: 40px;
  height: 40px;
}
.navbar-nav > .user-menu .user-image {
  width: 30px;
  height: 30px;
}
.navbar-nav > li > a .fa {
  font-size: 17px;
}
.sidebar-toggle,
.sidebar-toggle:hover {
  color: #222 !important;
}
.dropdown-toggle,
.user-header p {
  color: #222 !important;
}
.chart_option {
  margin: 10px 15px;
}
.clr15 {
  clear: both;
  height: 15px;
}
.Notification_tab .active {
  background: #fff;
}
.Notification_tab .nav-tabs-custom {
  box-shadow: none;
  margin-bottom: 10px;
}
.c_btn {
  background: #2a88dd;
  color: #fff;
}
.c_btn:hover {
  background: #3699f0;
  color: #fff;
}

.user_list_table {
  background: #ffffff !important;
  border: #dddddd solid 1px;
}
.main_hadding {
  font-size: 18px;
  font-weight: 700;
  margin: 0 0 15px 0;
}
.user_list_table thead tr th {
  background: #3b444f;
  color: #dddddd;
}
.user_list_table table {
  border-bottom: #3b444f solid 1px;
}
.pagination > .active > a,
.pagination > .active > a:focus,
.pagination > .active > a:hover,
.pagination > .active > span,
.pagination > .active > span:focus,
.pagination > .active > span:hover {
  z-index: 3;
  color: #fff;
  cursor: default;
  background-color: #3b444f;
  border-color: #3b444f;
}
.overview_box {
  border: #b7b7b7 solid 1px;
  border-radius: 3px;
  background: #fff;
}
.user_info {
  padding-top: 65px;
}
.log_table tr th {
  background: #222222;
  color: #fff;
  border: 0 !important;
}
.overview_box .nav-tabs > li {
  float: none;
  display: inline-block;
}
.overview_box .nav-tabs {
  text-align: center;
}
.mat-paginator-container {
  justify-content: space-between !important;
}
.ex-container {
  /* position: relative;
  min-height: 200px; */
}
.example-loading-shade {
  position: absolute;
  top: 0;
  left: 15px;
  bottom: 56px;
  right: 15px;
  background: rgba(0, 0, 0, 0.15);
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.search-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1px;
  flex-wrap: wrap-reverse;
  width: 100%;
}
.search-bar {
  width: 40% !important;
}
.mat-serch-icon {
  font-size: 20px !important;
}
.mat-icon-class {
  cursor: pointer;
  color: #3c8dbc;
  margin-left: 5px;
  font-size: 19px;
  width: 15px !important;
  height: 15px !important;
}
.input-form-full {
  min-width: 150px;
  max-width: 1000px;
  width: 100%;
  margin-left: 5px;
}
.input-form-mid {
  min-width: 150px;
  max-width: 1000px;
  width: 70%;
  margin-left: 5px;
}
.input-form-small {
  min-width: 150px;
  max-width: 1000px;
  width: 20%;
  margin-left: 5px;
}
.icon {
  font-size: 40px;
  color: #222222;
}
.mat-card {
  margin-top: 15px;
}
.myPanelClass {
  margin-top: 30px !important;
}
